<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">区块链溯源解决方案</p>
        <p class="header-desc">
          区块链溯源解决方案以区块链技术作为支撑，结合物联网、大数据、人工智能等先进技术，将商品从原材料采购、加工、生产、质检、物流、经销商、零售商的全流程信息记录上链，实现商品全生命周期内的可信溯源管理，目前已在酒水、茶叶、农产品、艺术品等行业实现应用落地。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          基于众享金联成熟的区块链底层设计，面向政府、企业、认证等各类型溯源行业组织提供安全、可信、易用的信息录入、设备管理、监管报送、溯源查询服务。平台关键环节采用物联网技术保证信息的自动生成和上链，降低人力成本;消费者在平台中将由被动受益者变为溯源生态参与者，汇聚营销大数据，帮助企业优化生产、提升政府监管效率，实现产品全生命周期追溯管理，做到来源可查、去向可追、责任可究。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">方案优势</div>
      <div class="advantage-content">
        <el-row :gutter="20">
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/traceability/advantage-1.png"
              />
              <div class="title">国产自主可控区块链底层平台</div>
              <div class="text">
                拥有国产自主可控区块链平台，支持国密算法，具备高性能、高安全、高可靠，高可信，行业领先。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/traceability/advantage-2.png"
              />
              <div class="title">全链条信息采集</div>
              <div class="text">
                将商品生产、加工、包装、出厂，以及仓储出入库、订单、物流等信息整合，实现商品全程品质信息可追溯。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/traceability/advantage-3.png"
              />
              <div class="title">多样化、个性化标识码</div>
              <div class="text">
                支持NFC、
                RFID、二维码、双因子二维码等各种形式的产品溯源标识技术，供客户按需选购。
              </div>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content">
              <img
                class="img"
                src="@/assets/img/solution/traceability/advantage-4.png"
              />
              <div class="title">专业数据服务</div>
              <div class="text">
                为客户梳理数据报表，提供数据可视化分析手段，全方位反映商品的防伪溯源状况，量化追溯带来的收益。
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
    <!-- 应用场景 -->
    <div class="setting">
      <div class="commom-title">应用场景</div>
      <div class="setting-content">
        <div class="list-tab">
          <div
            class="tabs"
            v-for="(item, index) in settingList"
            :key="index"
            :class="{ 'active-tab': index === tabIndex }"
            @click="tabIndex = index"
          >
            {{ item.tab }}
          </div>
        </div>
        <div class="list-detail">
          <div class="left">
            <div class="title">{{ settingList[tabIndex].title }}</div>
            <div class="text">
              {{ settingList[tabIndex].text }}
            </div>
          </div>
          <img class="right" :src="settingList[tabIndex].url" />
        </div>
      </div>
    </div>
    <!-- 合作案例 -->
    <div class="case">
      <div class="commom-title case-title">合作案例</div>
      <div class="case-content">
        <div class="case-menu">
          <div
            class="menu-item"
            v-for="(item, index) in caseList"
            :key="index"
            :class="{ 'active-case-tab': index === caseIndex }"
            @mouseenter="handleCaseChange(index)"
          >
            {{ item.title }}
          </div>
        </div>
        <el-carousel
          height="498px"
          direction="vertical"
          :autoplay="false"
          class="case-img"
          arrow="none"
          indicator-position="none"
          ref="carousel"
        >
          <el-carousel-item v-for="(item, index) in caseList" :key="index">
            <img class="case-img" :src="item.img" />
          </el-carousel-item>
        </el-carousel>

        <div class="case-desc">
          <div class="case-icon" v-if="caseList[caseIndex].icon">
            <img :src="caseList[caseIndex].icon" />
          </div>
          <div class="title">{{ caseList[caseIndex].text }}</div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      settingList: [
        {
          tab: "茶叶溯源",
          title: "茶叶溯源",
          text: "实现产业从种植、采摘、加工、质检、物流、销售的茶叶全生命周期溯源验证，提供防伪溯源一体化服务。消费者扫码验真，查验茶叶产地、生产过程、质检报告、区块链证书等全部信息。",
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-1.png',
        },
        {
          tab: "酒水溯源",
          title: "酒水溯源",
          text: "利用物联网等技术结合区块链对酒水从原料采购、生产加工、包装销售全过程环节进行数据采集，并上链存储实现全程追溯，结合NFT技术实现数字酒证与数字酒庄，结合标识码营销提升品牌影响力。",
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-2.png',
        },
        {
          tab: "农产品溯源",
          title: "农产品溯源",
          text: "建立从田间到餐桌的溯源体系，提供原产地防伪和农产品品质及安全的溯源服务，实现地方特色农产品原产地保真溯源，打造特色地域名片，助力地方地理标识产品的优质发展，推动乡村振兴。",
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-3.png',
        },
        {
          tab: "医药溯源",
          title: "医药溯源",
          text: "推动药品生产流通企业落实主体责任，使用物联网自动化技术采集留存覆盖从生产线到医院/药店的原料来源、生产过程、购销记录等信息，保证药品的全流程可追溯。",
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-4.png',
        },
        {
          tab: "跨境商品溯源",
          title: "跨境商品溯源",
          text: "从海外生产企业开始，记录单品和生产批次的对应关系，将海外质检机构抽样和监督、海外入仓、干线物流、国内质检、运输销售全程信息上链，覆盖从海外原厂到保税仓再到国内的全流程的溯源信息，保证跨境商品的可信追溯。",
          url:'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-5.png',
        },
        {
          tab: "通用溯源",
          title: "通用溯源",
          text: "基于众享溯源平台的灵活自定义能力，为其它多种行业的商品提供通用的区块链溯源服务，企业可根据不同商品类型按需制定对应的溯源模板。",
          url: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/setting-6.png',
        },
      ],
      tabIndex: 0,
      caseList: [
        {
          title: "地理标识产品溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-1.png',
          icon: require("@/assets/img/solution/traceability/case-icon-1.png"),
          text: "地理标识产品溯源",
        },
        {
          title: "智慧蒙自溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-2.png',
          icon: require("@/assets/img/solution/traceability/case-icon-2.png"),
          text: "“一乡一品”特色农产品区块链溯源系统",
        },
        {
          title: "仙茱链",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-3.png',
          icon: require("@/assets/img/solution/traceability/case-icon-3.png"),
          text: "国内首个开放式医药产业联盟链平台—— 仙茱链",
        },
        {
          title: "略阳乌鸡溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-4.png',
          text: "精准扶贫略阳乌鸡溯源",
        },
        {
          title: "饮用水溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-5.png',
          icon: require("@/assets/img/solution/traceability/case-icon-5.png"),
          text: "饮用水溯源『溯洄链』",
        },
        {
          title: "茶叶溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-6.png',
          icon: require("@/assets/img/solution/traceability/case-icon-6.png"),
          text: "茶叶溯源『茶查看』",
        },
        {
          title: "酒品溯源",
          img: 'https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/case-img-7.png',
          icon: require("@/assets/img/solution/traceability/case-icon-7.png"),
          text: "酒品溯源『酒源链』",
        },
      ],
      caseIndex: 0,
    };
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
  methods:{
    handleCaseChange(index){
      this.caseIndex = index;
      this.$refs.carousel.setActiveItem(index)
    }
  }
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/traceability/bg.png") center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0, 0, 0, 0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }

  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .grid-content {
        height: 388px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        text-align: center;
        .img {
          width: 124px;
          height: 124px;
          margin-top: 40px;
        }
        .title {
          margin: 28px 0px 24px;
          font-size: 19px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 26px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
          margin: 0px 16px;
        }
      }
    }
  }
  .setting {
    .setting-content {
      @extend .content-width;
      padding-bottom: 70px;
      .list-tab {
        height: 43px;
        border-bottom: 1px solid #e8eef9;
        display: flex;
        .tabs {
          flex: 1;
          height: 43px;
          font-size: 18px;
          color: rgba(0, 0, 0, 0.65);
          line-height: 26px;
          text-align: center;
          cursor: pointer;
          &:hover{
          font-family: "PingFangSC-Medium";
          color: #000000;
          transition: border-bottom 0.3s;

          }
        }
        .active-tab {
          font-family: "PingFangSC-Medium";
          color: #000000;
          border-bottom: 4px solid #0077ff;
          transition: border-bottom 0.3s;
        }
      }
      .list-detail {
        margin-top: 50px;
        height: 260px;
        background: #ffffff;
        box-shadow: 0px 0px 24px 0px rgba(18, 86, 188, 0.08);
        display: flex;
        .right {
          width: 460px;
          height: 260px;
        }
        .left {
          flex: 1;
          padding: 44px 60px 0px;
          .title {
            height: 26px;
            font-size: 22px;
            font-family: "PingFangSC-Medium";
            color: #000000;
            line-height: 26px;
            margin-bottom: 24px;
          }
          .text {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.65);
            line-height: 32px;
          }
        }
      }
    }
  }
  .case {
    background-color: #292c35;
    .case-title {
      color: #ffffff;
    }
    .case-content {
      @extend .content-width;
      padding-bottom: 70px;
      display: flex;
      .case-menu {
        width: 312px;
        background: rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        padding: 20px 16px;
        .menu-item {
          height: 66px;
          line-height: 66px;
          text-align: center;
          font-size: 22px;
          color: #ffffff;
          &:hover {
            cursor: pointer;
          }
        }
        .active-case-tab {
          background: #0077ff;
          border-radius: 4px;
        }
      }

      .case-img {
        width: 480px;
        height: 498px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .case-desc {
        flex: 1;
        height: 498px;
        background:  rgba(255,255,255,0.04);
        padding: 0px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .case-icon {
          height: 40px;
          img {
            height: 100%;
          }
        }
        .title {
          font-size: 26px;
          font-weight: 600;
          color: #ffffff;
          line-height: 38px;
          margin-top: 36px;
          margin-bottom: 12px;
        }
        .line {
          width: 30px;
          height: 4px;
          background: #0077ff;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
